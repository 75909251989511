<template>
  <v-container>
    <app-data-table
      :title="
              $vuetify.lang.t('$vuetify.titles.list', [
                $vuetify.lang.t('$vuetify.menu.shop_online')
              ])
            "
      :is-loading="isTableLoading"
      csv-filename="Configs"
      :headers="getTableColumns"
      :items="configs"
      :manager="'shop'"
      :sort-by="['name']"
      :sort-desc="[false, true]"
      multi-sort
      @create-row="newConfigHandler"
      @edit-row="editConfigHandler($event)"
      @delete-row="deleteConfigHandler($event)"
    >
      <template v-slot:[`item.logo`]="{ item }">
        <template v-if="item.logo">
          <v-avatar>
            <img
              :src="item.logo.original_url"
            >
          </v-avatar>
        </template>
        <template v-else>
          {{$vuetify.lang.t('$vuetify.no_defined')}}
        </template>
      </template>
      <template v-slot:[`item.paypal_email`]="{ item }">
        <v-tooltip v-if="(!item.paypal_email && !item.paypal_merchant_id) || item.cantArticle === 0"
                   bottom
                   max-width="150">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="warning"
                    v-bind="attrs"
                    v-on="on">
              mdi-lan-connect
            </v-icon>
          </template>
          <span v-if="(!item.paypal_email && !item.paypal_merchant_id)">{{$vuetify.lang.t('$vuetify.partners.no_paypal_asociate')}}</span>
          <span v-else>{{$vuetify.lang.t('$vuetify.partners.no_articles_online')}}</span>
        </v-tooltip>
        <v-icon color="primary" v-else>
          mdi-lan-connect
        </v-icon>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-icon>mdi-shopping</v-icon>
        {{ item.name }}
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <template>
          <a :href="item.url" target="_blank">
            {{ item.url }}
          </a>
        </template>
      </template>
      <template v-slot:[`item.permit_invite`]="{ item }">
        <v-icon v-if="item.permit_invite" color="primary">mdi-check-circle</v-icon>
        <v-icon v-else color="error">mdi-close-circle</v-icon>
      </template>
      <template v-slot:[`item.nameCountry`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar left>
                {{ item.countryFlag }}
              </v-avatar>
              {{ item.country }}
            </v-chip>
          </template>
          <span>{{ item.nameCountry }}</span>
        </v-tooltip>
      </template>
    </app-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'OnlineShopConfigList',
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('online', [
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'configs',
      'managerConfig',
      'isTableLoading'
    ]),
    ...mapState('auth', ['sessionData']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.component.logo'),
          value: 'logo',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.online.online'),
          value: 'paypal_email',
          select_filter: false
        },
        // {
        //   text: this.$vuetify.lang.t('$vuetify.online.template'),
        //   value: 'template',
        //   select_filter: true
        // },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shop.name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.address'),
          value: 'url',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.online.permit_invite'),
          value: 'permit_invite',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    await this.getConfigs()
  },
  methods: {
    ...mapActions('online', [
      'toogleNewModal',
      'openEditModal',
      'openShowModal',
      'getConfigs',
      'deleteConfig'
    ]),
    ...mapActions('auth', ['callNewAccount']),
    associateToStripe () {
      this.callNewAccount({ type: 'standard', origin: window.location.pathname })
        .then(() => {
          if (this.sessionData.url) {
            window.location = this.sessionData.url
          } else {
            this.$Swal.fire({
              title: this.$vuetify.lang.t('$vuetify.partners.existing_account'),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.error_associate'
              ),
              icon: 'warning',
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'red'
            })
          }
        })
    },
    newConfigHandler () {
      this.$store.state.online.managerConfig = false
      this.$router.push({ name: 'config_add' })
    },
    editConfigHandler ($event) {
      this.$store.state.online.managerConfig = true
      this.openEditModal($event)
      this.$router.push({ name: 'config_edit' })
    },
    deleteConfigHandler (shopId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.shop')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteConfig(shopId)
        })
    }
  }
}
</script>

<style scoped>

</style>
